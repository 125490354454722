import { datadogRum } from '@datadog/browser-rum';


if (process.env.NEXT_PUBLIC_DATADOG_APPID && process.env.NEXT_PUBLIC_DATADOG_TOKEN) {
    datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_APPID,
        clientToken: process.env.NEXT_PUBLIC_DATADOG_TOKEN,
        site: 'datadoghq.com',
        service: 'deal-web',
        env: process.env.NEXT_PUBLIC_DATADOG_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: process.env.NEXT_PUBLIC_BUILD_COMMIT_SHA,
        sampleRate: 100,
        trackInteractions: true,
    });
}
