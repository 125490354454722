import type { AppProps } from 'next/app';
import { FC,useEffect } from 'react';
import { useRouter } from 'next/router';

import 'shared/styles/_global.scss';
import 'utils/datadog';

const App: FC<AppProps> = ({ Component, pageProps }) => {
    const router = useRouter();

    useEffect(() => {
      const handleRouteChange = (url:string) => {
        window.gtag('config', process.env.NEXT_PUBLIC_GTM_ID as string, {
          page_path: url,
        });
      };
      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }, [router.events]);

  return <Component {...pageProps} />;
};

export default App;
